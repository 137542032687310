import React from "react";
import Toast from "../Toast/Toast";
import styles from "./QuoteModal.module.css";

export default function QuoteModal(props) {

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  const quotes = props?.quotes || {};

  return (
    <div className="modal fade" id={props?.modalID} tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id={`quoteModalLabel-${props?.modalID}`}>
              Referencia
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            {Object.keys(quotes).length > 0 &&
              Object.entries(quotes).map(([quoteTitle, quoteValues], index) => (
                <div className="row" key={index}>
                  <div className="col-2 text-center">
                    <b>{quoteTitle}</b>
                  </div>
                  <div className="col-8">
                    {`${quoteValues?.autor || ""} ${quoteValues?.fecha || ""} `}
                    <i>
                      {`${quoteValues?.titulo?.titulo || ""} ${quoteValues?.titulo?.subtitulo || ""} `}
                    </i>
                    {`${quoteValues?.personal || ""}`}
                    {`${quoteValues?.editorial || ""}`}
                  </div>
                  <div className="col-2 text-center">
                    <span
                      type="button"
                      id={`buttonQuote-${props?.item?.id}-${quoteTitle}`}
                      onClick={() =>
                        copyToClipboard(
                          `${quoteValues?.autor || ""} ${quoteValues?.fecha || ""} ${quoteValues?.titulo?.titulo || ""} ${quoteValues?.titulo?.subtitulo || ""}. ${quoteValues?.personal || ""} ${quoteValues?.editorial || ""}`
                        )
                      }
                    >
                      <i className="fas fa-copy"></i>
                    </span>
                  </div>
                  <Toast
                    buttonID={`buttonQuote-${props?.item?.id}-${quoteTitle}`}
                    toastID={`toast-${props?.item?.id}-${quoteTitle}`}
                    variant="secondary"
                  >
                    {`Cita en formato ${quoteTitle} copiada en portapapeles`}
                  </Toast>
                </div>
              ))}
          </div>
          <div className="modal-footer justify-content-center">
            <div className="row w-100 d-flex flex-row justify-content-center">
              <div className={`col-12 col-xl-10 border-start border-5 border-info shadow rounded p-3 d-flex flex-row ${styles.info_card}`}>
                <span className="badge rounded-pill text-info me-3">
                  <i className="fa fa-info"></i>
                </span>
                Esta es una referencia autogenerada con la información disponible en el registro, puede estar incompleta o contener datos erróneos. La indentación o formato se puede perder al copiar y pegar.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
