import React from 'react'

export default function MisionVisionScreen() {
    return (
        <section className="container">
            <div className="bg-white mx-0 mx-md-3 my-3 p-3 shadow-sm rounded">
                <h4 className='text-center mb-4'>Misión</h4>
                <div className="col-12 col-lg-8 offset-lg-2 mb-4">
                    <p align="justify" className="fw-lighter fst-italic">
                        Es nuestra misión educar y formar líderes íntegros, capaces, sensibles; protagonistas del desarrollo global. Con nuestra metodología basada en Neuropsicología Evolutiva y nuestro currículo innovador, en un ambiente de aprendizaje tecnológico, logramos el desarrollo del potencial humano.
                    </p>
                </div>
                <h4 className='text-center mb-4'>Visión</h4>
                <div className="col-12 col-lg-8 offset-lg-2">
                    <p align="justify" className="fw-lighter fst-italic">
                    Village School se mantendrá a la vanguardia en el campo educativo, destacando por la calidad humana, conocimiento y liderazgo de sus estudiantes. Ellos harán impacto en diferentes ámbitos, superando los retos de una sociedad cambiante.
                    </p>
                </div>
            </div>
        </section>
    )
}
